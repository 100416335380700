.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
    line-height: 1.6;
  }
  
  .about-container h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
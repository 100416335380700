.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 7px;
  }
  
  .nft {
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    margin: 7px;
  }
  
  input {
    background-color: rgba(0,0,0,0);
    border: 1px solid #fff;
    border-radius: 7px;
    text-align: center;
    color: #fff;
    padding: 7px;
    width: 90%;
  }

  button {
    background-color: rgba(0,0,0,0);
    color: #fff;
    padding: 7px 14px;
    margin: 7px;
    margin-top: 14px;
    border: 1px solid #fff;
    border-radius: 7px;
    padding-top: 9px;
    cursor: pointer;
    transition: 0.2s;
  }

  button:hover {
    background-color: rgba(255,255,255,0.1);
    transform: translateY(-2px);
  }

  button:active {
    background-color: rgba(255,255,255,0.3);
    transform: translateY(2px);
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.header {
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: rgb(40, 37, 41);
    padding: 1rem;
    color: white;
    font-weight: bold;
  }
  
  .header img {
    height: 40px;
    margin-right: 1rem;
  }

  .header button {
    position: fixed;
    top: 14px;
    right: 14px;
    background-color: rgb(40, 37, 41);
    border: 1px solid #fff;
    color: #fff;
    border-radius: 7px;
    padding: 7px 14px;
    cursor: pointer;
    transition: 0.2s;
    z-index: 999px;
  }

  .header button:hover {
    background-color: rgb(74, 67, 76);
    transform: translateY(-2px);
  }

  .header button:active {
    background-color: rgb(107, 100, 109);
    transform: translateY(2px);
  }

  .header button p {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 480px) {
    h1 {
      display: none;
    }
  }
  

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1d1d1e;
    padding: 1rem;
    color: white;
    font-family: monospace;
    font-size: 1.5em;
  }
  
  .navbar ul {
    list-style-type: none;
    display: flex;
    margin: auto;
    padding: 0;
  }
  
  .navbar li {
    margin-right: 1rem;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
  }
  
  .navbar a:hover {
    text-decoration: underline;
  }

  
body::-webkit-scrollbar {
  display: none;
}

.news-feed {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding: 1rem;
  }
  
  .news-card {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 300px;
    border: 1px solid #ccc;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  

  .news-card:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .news-card::-webkit-scrollbar {
    display: none;
  }
  
  .news-card img {
    width: 100%;
    height: auto;
  }

  .news-card a {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
  }
  
  .news-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 1rem;
  }
  
  .news-card-title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    text-align: left;
  }
  
  .news-card-source {
    font-size: 0.875rem;
    color: #e4dede;
    margin-bottom: 0.5rem;
  }
  
  .news-card-description {
    font-size: 1rem;
    color: #7b7b7b;
    text-align: left;
  }
  
  .generated-content-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .generated-content {
    max-width: 80%;
    background-color: rgb(56, 56, 56);
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 1.5;
    overflow-y: auto;
    max-height: 80%;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #121111;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .generated-content p {
    margin: 14px;
  }

  .news-card-footer {
    margin-top: auto;
  }

  .generate-button {
    background-color: rgba(0,0,0,0);
    color: #fff;
    padding: 7px 14px;
    display: block;
    margin: 7px auto;
    border: 1px solid #fff;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .generate-button:hover {
    background-color: rgba(255,255,255,0.3);
    transform: translateY(-2px);
  }
  
  .generate-button:active {
    background-color: rgba(255,255,255,0.6);
    transform: translateY(2px);
  }

  .mint-btn {
    background-color: rgba(0,0,0,0);
    color: #fff;
    padding: 7px 14px;
    margin: 7px;
    border: 1px solid #fff;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s;
  }

  .mint-btn:hover {
    background-color: rgba(255,255,255,0.3);
    transform: translateY(-2px);
  }

  .mint-btn:active {
    background-color: rgba(255,255,255,0.6);
    transform: translateY(2px);
  }

  .loader-cont {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    backdrop-filter: blur(5px);
  }

  .loader {
    border: 5px solid rgba(255,255,255,0.1);
    border-top: 5px solid rgba(255,255,255,0.5);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    width: 80%;
    max-width: 800px;
    max-height: 90vh;
    background-color: #151414;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    overflow: scroll;
  }
  
  .modal-content::-webkit-scrollbar {
    display: none;
}

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }